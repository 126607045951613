import {
  Bankcard,
  Boxes,
  Car,
  WayPoint,
  SyncAltDotted,
  DocumentChecked,
  Document,
  Customer,
  MapPinned,
} from '@oresundsbron/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { constants, CustomerType } from '@oresundsbron/validators';
import { useCustomerInformation } from '@oresundsbron/app-common';

interface SideNavigationLink {
  title: string;
  href: string;
  icon: ReactNode;
  key: string;
}

export function useSideNavigation(): SideNavigationLink[] {
  const { t } = useTranslation('account');
  const { locale } = useRouter();
  const { customerInformation } = useCustomerInformation();

  const customerType = customerInformation?.customerType;

  const isBusinessCommuter = useMemo(
    () => customerType === CustomerType.businessCommuter,
    [customerType]
  );

  const businessCommuterContract = useMemo(() => {
    if (isBusinessCommuter) {
      return customerInformation?.contracts.find(
        (x) => x?.contractType === CustomerType.businessCommuter
      )?.contractNo;
    }
  }, [customerInformation, isBusinessCommuter]);

  const paymentIssuer = useMemo(() => {
    if (
      customerInformation?.contracts &&
      customerInformation?.contracts.length > 0
    ) {
      return customerInformation?.contracts[0].paymentIssuer;
    } else {
      return '';
    }
  }, [customerInformation?.contracts]);

  const linksToHide = useMemo(() => {
    const linksToHide: string[] = [];

    if (paymentIssuer && constants.externalIssuers.includes(paymentIssuer)) {
      linksToHide.push('invoices', 'debitCard');
    }

    if (customerType === CustomerType.affiliatePartner) {
      linksToHide.push('commuters', 'debitCard');
    }

    if (customerType === CustomerType.businessCommuter) {
      linksToHide.push(
        'customerInformation',
        'agreement',
        'commuters',
        'invoices',
        'debitCard',
        'kmtoll'
      );
    }

    if (customerType === CustomerType.privateCustomer) {
      linksToHide.push('commuters', 'kmtoll');
    }

    return linksToHide;
  }, [customerType, paymentIssuer]);

  const iconColor = 'text-inherit';

  return useMemo(
    () =>
      [
        {
          title: t('navigation.dashboard'),
          href: `/${locale}/account/dashboard`,
          icon: (
            <Boxes
              width="1em"
              height="1em"
              className={`${iconColor}`}
              color="inherit"
            />
          ),
          key: 'dashboard',
        },
        {
          title: t('navigation.customerInformation'),
          href: `/${locale}/account/customerInformation`,
          icon: (
            <Customer
              width="1em"
              height="1em"
              className={`${iconColor}`}
              color="inherit"
            />
          ),
          key: 'customerInformation',
        },
        {
          title: t('navigation.agreement'),
          href: `/${locale}/account/agreement`,
          icon: (
            <DocumentChecked
              width="1em"
              height="1em"
              className={`${iconColor}`}
              color="inherit"
            />
          ),
          key: 'agreement',
        },
        {
          title: t('navigation.recognitions'),
          href: `/${locale}/account/vehicles${
            isBusinessCommuter ? `?contract=${businessCommuterContract}` : ''
          }`,
          icon: <Car width="1em" height="1em" className={`${iconColor}`} />,
          key: 'recognitions',
        },
        {
          title: t('navigation.commuters'),
          href: `/${locale}/account/commuters`,
          icon: (
            <SyncAltDotted
              width="1em"
              height="1em"
              className={`${iconColor}`}
            />
          ),
          key: 'commuters',
        },
        {
          title: t('navigation.trips'),
          href: `/${locale}/account/trips`,
          icon: (
            <WayPoint width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'trips',
        },
        {
          title: t('navigation.invoices'),
          href: `/${locale}/account/invoices`,
          icon: (
            <Document width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'invoices',
        },
        {
          title: t('navigation.debitCard'),
          href: `/${locale}/account/debit-card`,
          icon: (
            <Bankcard width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'debitCard',
        },
        {
          title: t('navigation.roadTaxDenmark'),
          href: `/${locale}/account/bizz`,
          icon: (
            <MapPinned width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'kmtoll',
        },
      ].filter(
        (link: SideNavigationLink) => !linksToHide.includes(link.key)
      ) as SideNavigationLink[],
    [t, locale, isBusinessCommuter, businessCommuterContract, linksToHide]
  );
}
