import { FC, PropsWithChildren, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIsAuthenticated } from '../../../hooks/useIsAuthenticated';
import { PageWrapper } from '@oresundsbron/bridge-ui';
import AccountContent, { AccountContentProps } from './AccountContent';
import { SEOMetadata } from '~/components/SEOMetadata';
import AccountBanner from './AccountBanner';
import { Eq as StringEq } from 'fp-ts/lib/string';
import { useTranslation } from 'next-i18next';
import { useUser } from '@auth0/nextjs-auth0/client';

const AccountLayout: FC<PropsWithChildren<AccountContentProps>> = ({
  children,
  ...accountContentProps
}) => {
  const router = useRouter();
  const { t } = useTranslation('account');
  const { authenticated, loading } = useIsAuthenticated();
  const { user, isLoading } = useUser();
  const useAuth0 = process.env.NEXT_PUBLIC_USE_AUTH0 === 'true';
  const isDashboardPage = router.asPath.includes('dashboard');

  useEffect(() => {
    if (!useAuth0 && !authenticated && !loading) {
      router.replace('/account/login');
    } else if (useAuth0 && !user && !isLoading) {
      const locale = router.locale;
      router.replace(`/api/auth/login?locale=${locale}`);
    }
  }, [router, authenticated, loading, useAuth0, isLoading, user]);

  if (loading || isLoading) {
    return null;
  }

  if (
    (!useAuth0 && authenticated) ||
    (useAuth0 && (user !== null || user !== undefined))
  ) {
    return (
      <>
        <SEOMetadata items={[]} />
        <PageWrapper className="relative min-h-[calc(100dvh-180px)] ">
          {!isDashboardPage && (
            <AccountBanner
              title={t('layout.title')}
              navigationLabel={t('navigation.dashboard')}
              dashboard={StringEq.equals(
                accountContentProps.namespace,
                'dashboard'
              )}
            />
          )}
          <AccountContent {...accountContentProps}>{children}</AccountContent>
        </PageWrapper>
      </>
    );
  }

  return <></>;
};
export default AccountLayout;
