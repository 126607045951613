import { Box, Button, PageWrapper, Typography } from '@oresundsbron/bridge-ui';
import { Close, Logout } from '@oresundsbron/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useLogout } from '../../../hooks/useLogout';
import { useSideNavigation } from '../../../hooks/useSideNavigation';
import { getCustomerType } from '../../../stores/auth';
import { Root, Item, List } from '@radix-ui/react-navigation-menu';
import * as Dialog from '@radix-ui/react-dialog';
import { Eq as StringEq } from 'fp-ts/lib/string';
import { DefaultLink } from '~/components/Links/DefaultLink';
import { useCorporateGroup } from '~/modules/account/CorporateGroup/useCorporateGroup';
import { CorporateGroupSideMenu } from '@oresundsbron/app-common';

interface MobileNavigationProps {
  isOpen: boolean;
  onOpenChange(open: boolean): void;
}

const MobileNavigation: FC<MobileNavigationProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const { t } = useTranslation(['account', 'common'], {
    nsMode: 'fallback',
  });
  const logout = useLogout();
  const router = useRouter();
  const customerType = getCustomerType();
  const links = useSideNavigation();
  const { corporateGroup } = useCorporateGroup();

  useEffect(() => {
    router.events.on('routeChangeStart', () => onOpenChange(false));
    return () =>
      router.events.off('routeChangeStart', () => onOpenChange(false));
  }, [onOpenChange, router]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-20" />
        <Dialog.Content className="fixed inset-0 z-30 bg-primary-900">
          <Dialog.Close asChild>
            <Button
              intent="text"
              aria-label={t('action.close')}
              className="absolute right-3 top-4 sm:right-10 sm:top-8"
              startIcon={<Close />}
              color="white"
              iconSize="lg"
            />
          </Dialog.Close>
          <PageWrapper className="py-4 sm:py-8" as="div">
            <Typography
              intent="title"
              size="lg"
              id="account-navigation-heading"
              className="col-start-2 mb-2 text-white"
            >
              {t('account.title')}
            </Typography>
            {!StringEq.equals(customerType, 'business-commuter') ? (
              <>
                <Root
                  value={router.asPath}
                  defaultValue={router.asPath}
                  orientation="vertical"
                  className="col-start-2"
                  aria-labelledby="#account-navigation-heading"
                >
                  {corporateGroup.length > 0 && <CorporateGroupSideMenu />}
                  <List>
                    {links.map(({ href, icon, title }) => (
                      <Item value={href} className="py-3 text-white" key={href}>
                        <DefaultLink
                          color="inherit"
                          href={href}
                          className="flex items-center gap-2 no-underline data-[active]:font-medium data-[active]:underline"
                          active={href.includes(router.asPath)}
                          navLinkWrapper
                          underline={false}
                        >
                          {icon}
                          {title}
                        </DefaultLink>
                      </Item>
                    ))}
                  </List>
                </Root>
                <Box className="col-start-2 my-2 h-[1px] bg-white" />
              </>
            ) : null}
            <Button
              intent="text"
              startIcon={<Logout fontSize="1.56rem" />}
              className="col-start-2 pl-0 text-white"
              onClick={logout}
            >
              {t('action.logout')}
            </Button>
          </PageWrapper>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default MobileNavigation;
